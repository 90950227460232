<template>
  <div class="row">
    <div class="flex xs12">
      <h5>{{$t('reports.assignments.data')}}</h5>
      <local-table
        class="table-hover table-striped"
        :columns="reportsFields"
        :data="reports"
        :top-options="['xlsx']"
        :loading="loading || isLoading"
        @download-item="prepareDownload"
      />
    </div>
    <div class="flex xs12">
      <h5>{{$t('reports.assignments.totals')}}</h5>
      <local-table
        class="table-hover table-striped"
        :columns="totalsFields"
        :data="totals"
        :loading="loading || isLoading"
      />
    </div>
    <div
      class="col-12 title"
      v-show="hasData"
    >
      <va-button
        color="success"
        :disabled="loading || isLoading"
        @click="prepareDownload"
      >
        <text-loading
          :loading="loading"
          icon="fa fa-file-excel-o"
        >
          {{ $t('tables.actions.export', {format: 'Excel'}) }}
        </text-loading>
      </va-button>
    </div>
  </div>
</template>

<script>
const LocalTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/LocalTable')

export default {
  name: 'assignment-report',
  components: {
    LocalTable,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    reportsList: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  computed: {
    reportsFields () {
      return [
        {
          name: 'country',
          title: this.$t('tables.headings.country'),
          visible: this.differentCountries(),
          callback: this.translatedLabel,
        },
        {
          name: 'team',
          title: this.$t('tables.headings.code'),
        },
        {
          name: 'complete',
          title: this.$t('tables.headings.complete_report'),
          callback: this.completeLabel,
        },
        {
          name: 'jfhp',
          title: this.$t('tables.headings.jfhp'),
          callback: this.assignmentFormat,
          width: '50px',
        },
        {
          name: 'country_assignment',
          title: this.$t('tables.headings.country_assignment'),
          callback: this.assignmentFormat,
          width: '150px',
        },
        {
          name: 'team_assignment',
          title: this.$t('tables.headings.team_assignment'),
          callback: this.assignmentFormat,
          width: '150px',
        },
      ]
    },
    totalsFields () {
      return [
        {
          name: 'country',
          title: this.$t('tables.headings.country'),
          callback: this.translatedLabel,
        },
        {
          name: 'jfhp',
          title: this.$t('tables.headings.jfhp'),
          callback: this.assignmentFormat,
          width: '50px',
        },
        {
          name: 'country_assignment',
          title: this.$t('tables.headings.country_assignment'),
          callback: this.assignmentFormat,
          width: '150px',
        },
        {
          name: 'team_assignment',
          title: this.$t('tables.headings.team_assignment'),
          callback: this.assignmentFormat,
          width: '150px',
        },
        {
          name: 'total',
          title: this.$t('tables.headings.final_assignment'),
          callback: this.assignmentFormat,
          width: '150px',
        },
      ]
    },
    hasData () {
      return Array.isArray(this.reports) && this.reports.length > 0
    },
  },
  data () {
    return {
      isLoading: false,
      currentProyection: {},
      reports: [],
      totals: [],
    }
  },
  watch: {
    reportsList (val) {
      this.fixData(val)
    },
  },
  methods: {
    fixData (reports) {
      const r = reports.slice(0)

      this.reports = r.slice(0)
      this.getTotals()
    },
    differentCountries () {
      if (this.reports.length === 0) {
        return false
      }

      const first = this.reports[0]
      return this.reports.some(r => r.country !== first.country)
    },
    translatedLabel (name) {
      return this.$t(name)
    },
    completeLabel (approval) {
      const a = approval ? 'yes' : 'no'
      return this.$t('layout.' + a)
    },
    assignmentFormat (assignment) {
      return '$' + parseFloat(assignment).toFixed(2)
    },
    getTotals () {
      let countries = this.reports.map(r => r.country)
      countries = [...new Set(countries)]

      const totals = []
      for (const c of countries) {
        const current = {
          country: c,
          country_assignment: 0,
          jfhp: 0,
          team_assignment: 0,
          total: 0,
        }
        for (const r of this.reports) {
          if (r.country !== c) {
            continue
          }

          current.jfhp += r.jfhp
          current.country_assignment += r.country_assignment
          current.team_assignment += r.team_assignment
        }

        current.total = current.country_assignment + current.team_assignment
        totals.push(current)
      }
      this.totals = totals.slice(0)
    },
    prepareDownload () {
      this.$emit('export')
    },
  },
}
</script>
<style scoped>
.title {
  padding-top: 15px;
}
</style>
